<template>
	<div id="fundsLockManager">
		<div class="button-group">
			<router-link to="/bankView" title="Back to Bank Manager" class="btn">{{ languageStrings.backToBBankManager }}</router-link>
			<div id="unclaimedButton" class="checkbox-button btn">
				<label for="unclaimedLocksOnly">{{ languageStrings.unclaimedLocksOnly }}</label>
				<input type="checkbox" id="unclaimedLocksOnly" v-model="unclaimedLocksOnly" />
			</div>
		</div>
		<h1>{{ languageStrings.fundsLockManager }}</h1>
		<h3 v-if="cashierState.playerAccount.user.displayName != ''">
			{{ languageStrings.Player }}: {{ cashierState.playerAccount.user.displayName }}
		</h3>
		<div>
			<div v-if="this.fundsLocks.length > 0" class="funds-locks-container">
				<table class="">
					<tr class="header-row">
						<th>{{ languageStrings.idText }}</th>
						<th>{{ languageStrings.awardDate }}</th>
						<th>{{ languageStrings.playRecordId }}</th>
						<th>{{ languageStrings.RegularAU }}</th>
						<th>{{ languageStrings.cashText }}</th>
						<th>{{ languageStrings.claimAgentInitials }}</th>
						<th>{{ languageStrings.claimDate }}</th>
						<th v-if="fundsHaveAction">{{ languageStrings.actionsText }}</th>
					</tr>
					<tr
						class="data-row"
						v-for="(item, index) in fundsLocks"
						:key="index"
						:class="item.claimDate ? 'claimed' : 'unclaimed'"
						:title="item.claimDate ? 'Claimed' : 'Unclaimed. Click an action on the right.'"
					>
						<td>{{ item.id }}</td>
						<td>{{ item.awardDate }}</td>
						<td v-if="item.playRecordId">{{ item.playRecordId }}</td>
						<td v-else>{{ languageStrings.playRecordArchived }}</td>
						<td>{{ inGameCurrencyTool.formatFromAU(item.valueAU, inGameCurrencyTool.displayType.minorOrFull) }}</td>
						<td>{{ systemCurrencyTool.formatFromAU(item.valueAU, systemCurrencyTool.displayType.minorOrFull) }}</td>
						<td class="claim-agent">
							<span
								:style="
									item.claimAgentUserId
										? `background-color: #${generateUserIdColor(item.claimAgentUserId).background}; color: #${
												generateUserIdColor(item.claimAgentUserId).color
										  }`
										: ''
								"
								>{{ item.claimAgentInitials }}</span
							>
						</td>
						<td>{{ item.claimDate }}</td>
						<td v-if="!item.claimDate">
							<span class="btn" @click="releaseFundsLock(item.id)">{{ languageStrings.Unlock }}</span>
						</td>
					</tr>
				</table>
			</div>
			<div v-else>
				<h2>{{ languageStrings.noLocksFound }}</h2>
			</div>
		</div>
	</div>
</template>

<script>
import sharedScripts from "@/dependencies/sharedScripts";
import router from "@/router";
import { onBeforeUnmount } from "vue";

export default {
	name: "FundsLockManager",
	props: {
		cashierState: {
			type: Object,
		},
		appDataBus: {
			type: Object,
		},
		inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	setup() {},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			playerAccount: {},
			currencyInfo: this.cashierState.currencyInfo,
			fundsLocks: [],
			fundsHaveAction: false,
			unclaimedLocksOnly: true,
			fundsLockId: "",
			action: "",
		};
	},
	watch: {
		unclaimedLocksOnly() {
			this.listLockedFunds();
		},
	},
	created() {
		let thisInstance = this;
		onBeforeUnmount(() => {
			const unclaimedButton = document.getElementById("unclaimedButton");
			unclaimedButton.removeEventListener("click", thisInstance.checkboxButtonWrapper);
		});
	},
	mounted() {
		this.listLockedFunds();
		const unclaimedButton = document.getElementById("unclaimedButton");
		const unclaimedCheckbox = document.getElementById("unclaimedLocksOnly");
		this.checkboxButtonWrapper = function(e) {
			if (e.target.id == "unclaimedButton") {
				unclaimedCheckbox.checked = unclaimedCheckbox.checked == true ? false : true;
				unclaimedCheckbox.dispatchEvent(new Event("change", { bubbles: true }));
			}
		};
		unclaimedButton.addEventListener("click", this.checkboxButtonWrapper);
	},
	methods: {
		async listLockedFunds() {
			let thisInstance = this;
			this.playerAccount = this.cashierState?.playerAccount || {};
			let fundsLocks = this.playerAccount?.account?.fundsLocks || [];
			let fundsLocked = 0;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			if (Array.isArray(fundsLocks)) {
				fundsLocks.forEach((item) => {
					item.awardDate = new Date(item.awardDate).toLocaleTimeString([], thisInstance.dateOptions);
					item.claimDate = item.claimDate ? new Date(item.claimDate).toLocaleTimeString([], thisInstance.dateOptions) : "";
					if (!item.claimDate) fundsLocked++;
				});
			}

			if (fundsLocked > 0) this.fundsHaveAction = true;

			if (this.unclaimedLocksOnly) {
				fundsLocks = fundsLocks.filter((lockedFund) => lockedFund?.claimDate === "");
			}

			this.fundsLocks = fundsLocks.sort((a, b) => (a.awardDate ? -1 : 1));
		},
		async releaseFundsLock(fundsLockId) {
			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverStatus.serverBusy = false;
				this.serverStatus.busyText = "";
				return false;
			}

			let _state = this.cashierState;
			let body = {
				FundsLockId: fundsLockId,
				AccountAccessCode: _state.playerAccount.accountAccessCode,
			};

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${_state.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("api/v1/funds/lock/release", this.rabbitsfootHostUrl);

			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});
			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					fetchStatus.message = `Release Funds Lock failed: ${fetchStatus.message ? fetchStatus.message : fetchStatus.code}`;
					if (fetchStatus.code === 400) {
						_state.playerAccount.accountAccessCode = null;
						this.eventBus.emit("updateCashierState", _state);
						this.eventBus.emit("requestRefreshPlayerCode");
					}
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.code === 409 || fetchStatus.code === 401) this.eventBus.emit("forceLogout");
					return;
				}

				let dataObj = await response.json();

				if (fetchStatus.ok) {
					switch (dataObj.status) {
						case "InvalidAccountAccessCode":
							this.status.message = this.languageStrings.invalidAccountAccessCode;
							this.status.ok = false;
							_state.playerAccount.accountAccessCode = null;
							this.eventBus.emit("updateCashierState", _state);
							this.eventBus.emit("requestRefreshPlayerCode");
							return false;
						case "FundsLockAlreadyClaimed":
							this.status.ok = false;
							this.status.message = this.languageStrings.fundsLockAlreadyClaimed;
							this.eventBus.emit("updateStatus", this.status);
							return false;
						case "UserNotFound":
							this.status.ok = false;
							this.status.message = this.languageStrings.userNotFound;
							this.eventBus.emit("updateStatus", this.status);
							return false;
					}
				}

				_state.playerAccount.account = dataObj.account || {};
				this.fundsLocks = _state.playerAccount.account.fundsLocks;
				this.status.ok = fetchStatus.ok;
				this.status.message = this.status.ok
					? `${this.languageStrings.fundsLockText} #${fundsLockId} ${this.languageStrings.hasBeenRFeleased}`
					: fetchStatus;

				this.listLockedFunds();

				this.eventBus.emit("updateCashierState", _state);
				this.eventBus.emit("updateStatus", this.status);
			} catch (e) {
				this.status.code = e;
				this.status.ok = false;
				this.status.userMustDismiss = true;
				this.eventBus.emit("updateStatus", status);
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
	text-align: center;
}

#fundsLockManager {
	position: relative;
	width: 80%;
	margin-left: 20%;
}

#fundsLockManager table {
	width: 100%;
	padding: 15px;
	text-align: center;
}

.btn {
	text-align: center;
}

/* #unclaimedButton {
	position: absolute;
	top: 60px;
	left: 15px;
} */

/* .back-btn {
	position: absolute;
	margin-left: 30px;
} */

.funds-locks-container {
	height: calc(100vh - 20em);
	overflow: hidden auto;
}

.funds-locks-container table {
	margin: auto;
	width: 100%;
	padding: 15px;
}

.funds-locks-container::-webkit-scrollbar-track {
	box-shadow: inset 1px 1px 6px rgb(0 0 0 / 75%);
	border-radius: 16px;
	background-color: #434250;
}

.funds-locks-container::-webkit-scrollbar {
	width: 32px;
}

.funds-locks-container::-webkit-scrollbar-thumb {
	border-radius: 16px;
	box-shadow: inset -2px -2px 6px rgb(0 0 0 / 75%);
	background-color: #bccfe5;
}

.button-group {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}
</style>
